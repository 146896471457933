<div class="maria-button-dialog "
     [class.btn-large]="btnLarge"
     *ngIf="messageByType">
  <div class="close-dialog">
    <button (click)="closeDialogMessage()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <h2 >Assistente de Saúde</h2>
  @for (message of messageByType.messages; track message) {
    <p *ngIf="message.type  === 'text'" [innerHTML]="message.message"></p>
    <button (click)="goTo(message.button)" *ngIf="message.type  === 'button'" mat-stroked-button class="maria">
      {{ message.button | stringFormat }}
    </button>
  }
</div>
