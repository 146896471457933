import { Component, Input, OnDestroy } from '@angular/core';
import { PersonMessagesEntityService } from '../../../../state/entity-services/person-messages-entity.service';
import { UtilsService } from '../../../services/utils.service';
import { Observable, tap, Unsubscribable } from 'rxjs';
import { AutoUnsubscribe, CombineSubscriptions } from '../../../decorators/auto-unsubscribe.decorator';
import { PersonMessage } from '../../../../state/models/person-messages';
import { GetMessageByType, MariaUtilsService } from '../../services/maria-utils.service';
import { LocalStoreService } from '../../../services/local-store.service';
import { Router } from '@angular/router';
import { MariaFakeApiService } from '../../services/maria-fake-api.service';
import { MariaDialogsService } from '../../services/maria-dialogs.service';

@Component({
  selector: 'ia-button-dialog-system',
  templateUrl: './maria-button-dialog-system.component.html',
  styleUrls: ['../../style/maria.scss']
})
@AutoUnsubscribe()
export class MariaButtonDialogSystemComponent implements OnDestroy {
  @CombineSubscriptions()
  private subscriptions: Unsubscribable;

  @Input() btnLarge: boolean;
  @Input() digitalStatus: string;

  dialogPersonMessage: PersonMessage;
  messageByType: GetMessageByType;

  private localStorageName = 'PERSON_MESSAGES_IDS';

  constructor(
    public mariaDialogsService: MariaDialogsService,
    private mariaFakeApiService: MariaFakeApiService,
    private router: Router,
    private localStoreService: LocalStoreService,
    private mariaUtilsService: MariaUtilsService,
    private utilsService: UtilsService,
    private personMessagesEntityService: PersonMessagesEntityService,
  ) {

    this.mariaFakeApiService.setFirstInteractions();

    this.subscriptions = this.getLastPersonMessage()
      .subscribe({
        error: err => {
          this.utilsService.setErrorToast(err);
        }
      });
  }

  ngOnDestroy() {
  }

  openChat() {
    this.mariaDialogsService.openChat().subscribe();
  }

  getLastPersonMessage(): Observable<PersonMessage> {
    return this.personMessagesEntityService.getMessagesApiFromSystem(this.mariaFakeApiService.enableFake)
      .pipe(
        tap(personMessage => {
          const personMessagesIds = this.getPersonMessagesIds;
          if (!personMessagesIds.includes(personMessage.id)) {
            this.dialogPersonMessage = personMessage;
            this.messageByType = this.mariaUtilsService.getMessageByType(personMessage.contentBlocks, personMessage.source);

          }
        })
      );
  }

  get getPersonMessagesIds(): number[] {
    const personMessagesIds = this.localStoreService.getItem(this.localStorageName);
    if (!personMessagesIds) return [];
    return personMessagesIds;
  }

  closeDialogMessage() {
    this.messageByType = undefined;
    let msgIds = [];
    const personMessagesIds = this.getPersonMessagesIds;
    if (!personMessagesIds || personMessagesIds?.length === 0) {
      msgIds.push(this.dialogPersonMessage.id);
    } else {
      msgIds = personMessagesIds;
      if (!msgIds.includes(this.dialogPersonMessage.id)) {
        msgIds.push(this.dialogPersonMessage.id);
      }
    }
    this.localStoreService.setItem(this.localStorageName, msgIds);
  }

  goTo(button: string) {
    this.closeDialogMessage()
    const url = this.mariaUtilsService.getUrlByGoToEnums(button, this.digitalStatus);
    if (url) {
      this.router.navigate([url], {
        queryParamsHandling: 'merge'
      });
    }
  }
}
