import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AmplitudeService } from '../../../services/amplitude.service';
import { FormControl } from '@angular/forms';
import { PersonMessagesEntityService } from '../../../../state/entity-services/person-messages-entity.service';
import { UtilsService } from '../../../services/utils.service';
import { ButtonOptions, ContentBlock, PersonMessage } from '../../../../state/models/person-messages';
import { switchMap, tap, Unsubscribable } from 'rxjs';
import { AutoUnsubscribe, CombineSubscriptions } from '../../../decorators/auto-unsubscribe.decorator';
import { marked } from 'marked';
import { JwtAuthService } from '../../../services/auth/jwt-auth.service';
import { Person } from '../../../../state/models/person';
import { filter, map } from 'rxjs/operators';
import { MariaDialogsService } from '../../services/maria-dialogs.service';
import { ConfirmService } from '../../../services/app-confirm/confirm.service';
import { WhatsappService } from '../../../services/whatsapp.service';
import { MariaUtilsService } from '../../services/maria-utils.service';
import { Router } from '@angular/router';
import { PersonEntityService } from '../../../../state/entity-services/person-entity.service';
import { CrmPersons } from '../../../../state/models/crm-persons';
import { MariaFakeApiService } from '../../services/maria-fake-api.service';

@Component({
  selector: 'maria-chat-dialog',
  templateUrl: './maria-chat-dialog.component.html',
  styleUrls: ['./maria-chat-dialog.component.scss']
})
@AutoUnsubscribe()
export class MariaChatDialogComponent implements OnDestroy, AfterViewInit {
  messageControl = new FormControl(null);
  messages: MessageChat[] = [];
  buttonOptions: ButtonOptions[] = [];
  loading = false;
  loadingFirstInteraction = false;
  @CombineSubscriptions()
  private subscriptions: Unsubscribable;
  interaction = false;

  person: Person = this.jwtAuthService.getUser();
  crmPerson: CrmPersons;

  @ViewChild('scrollContainer') private scrollContainer!: ElementRef;
  @ViewChild('message') private input!: ElementRef;
  title: string[] = this.getTitle;

  constructor(
    private mariaFakeApiService: MariaFakeApiService,
    private personEntityService: PersonEntityService,
    private mariaUtilsService: MariaUtilsService,
    private router: Router,
    private whatsappService: WhatsappService,
    private confirmService: ConfirmService,
    private mariaDialogsService: MariaDialogsService,
    private jwtAuthService: JwtAuthService,
    private utilsService: UtilsService,
    private personMessageEntityService: PersonMessagesEntityService,
    private amplitudeService: AmplitudeService,
    private dialogRef: MatDialogRef<MariaChatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.amplitudeService.sendEvent('ai_assistant_chat_opened', { stepName: location.pathname });
    this.personMessageEntityService.getMessagesApi({}, this.mariaFakeApiService.enableFake).subscribe({
      next: data => {
        this.formatMessage(data);
      },
      complete: () => {
        this.loadingFirstInteraction = true;
      }
    });

    this.subscriptions = this.personEntityService.crmPersons(this.person.id)
      .pipe(tap(crmPerson => {
        this.crmPerson = crmPerson;
      }))
      .subscribe({
        error: error => {
          this.utilsService.setErrorToast(error);
        }
      });
  }

  ngOnDestroy() {
    this.mariaUtilsService.setUserFirstChatOpen();
  }

  ngAfterViewInit(): void {

  }

  get getTitle(): string[] {
    const personName = this.person?.personProperties?.name?.split(' ')[0];
    if (!this.mariaUtilsService.hasUserFirstChatOpen) {
      return [`Olá ${ personName }, ${ this.getGreeting }`, 'Sou sua Assistente de Saúde', 'Assistente de Saúde'];
    }
    return [`Olá ${ personName }, ${ this.getGreeting }`, 'Assistente de Saúde'];
  }

  get getGreeting() {
    const hour = new Date().getHours();

    if (hour >= 5 && hour < 12) {
      return 'bom dia!';
    } else if (hour >= 12 && hour < 18) {
      return 'boa tarde!';
    } else {
      return 'boa noite!';
    }
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      try {
        this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
      } catch (err) {
        console.error('Erro ao tentar rolar para o final:', err);
      }
    }, 10);
  }

  formatMessage(personMessages: PersonMessage[], updateMessages: boolean = false): void {
    const personMessagesNew = [...personMessages];
    personMessagesNew.reverse().forEach(pm => {
      if (!updateMessages) {
        this.messages = this.messages.filter(message => message.id !== 0);
      }
      const existingMessage = this.messages.find(m => m.id === pm.id);
      if (updateMessages && existingMessage) {
        existingMessage.rating = pm.userRating;
      } else if (!existingMessage) {
        this.messages.push({
          id: pm.id,
          type: pm.source.toLocaleLowerCase(),
          chat: this.getMessageByType(pm.contentBlocks, pm.source),
          rating: pm.userRating
        });
      }
    });

    const [personMessage] = personMessages;
    const { contentBlocks } = personMessage || {};
    const hasButtonOptions = contentBlocks.some(cb => cb.type === 'BUTTON_OPTIONS');
    if (!hasButtonOptions) {
      this.buttonOptions = [];
    }

    if (!updateMessages) {
      this.scrollToBottom();
    }
  }

  getMessageByType(contentBlocks: ContentBlock[], source: PersonMessage['source']): any[] {
    let messages: MessageByType[] = [];
    contentBlocks?.forEach(contentBlock => {
      if (contentBlock.type === 'PLAIN_TEXT') {
        messages.push({ type: 'text', message: contentBlock.plainText.plainText });
      }
      if (contentBlock.type === 'MARKDOWN') {
        messages.push({ type: 'text', message: marked(contentBlock.markdown.markdown) });
      }
      if (contentBlock.type === 'GO_TO') {
        messages.push({ type: 'button', button: contentBlock.goTo.destination });
      }
      if (contentBlock.type === 'BUTTON_OPTIONS') {
        const { buttonOptions = [] } = contentBlock;
        this.buttonOptions = buttonOptions;

      }
      if (contentBlock.type === 'BUTTON_CLICK') {
        messages.push({ type: 'text', message: contentBlock.buttonClick.plainText });
      }
    });
    return messages;
  }

  sendMessage(buttonOption?: ButtonOptions) {

    if (buttonOption) {
      this.amplitudeService.sendEvent('ai_assistant_suggested_question_clicked', { question: buttonOption.shortText });
    }


    this.loading = true;
    this.scrollToBottom();
    this.messageControl.disable({ onlySelf: true });
    this.interaction = true;
    this.subscriptions = this.personMessageEntityService.sendMessage({
      contentBlocks: [
        buttonOption ? this.setButtonClick(buttonOption) : this.setPlainText(),
      ]
    }, this.mariaFakeApiService.enableFake)
      .pipe(
        tap(() => this.amplitudeService.sendEvent('ai_assistant_message_sent')),
        switchMap(msg => {
          return this.personMessageEntityService.getMessagesApi({}, this.mariaFakeApiService.enableFake).pipe(
            tap(response => {
              this.scrollToBottom();
              this.formatMessage(response);
            })
          );
        })
      )
      .subscribe({
        complete: () => {
          this.messageControl.enable({ onlySelf: true });
          this.messageControl.reset();
          if (!buttonOption) {
            this.input.nativeElement.focus();
          }
          this.loading = false;
        },
        error: err => {
          this.loading = false;
          this.utilsService.setErrorToast(err);
        }
      });
  }

  setButtonClick(buttonOption: ButtonOptions): ContentBlock {
    const { plainText } = buttonOption;
    this.messages.push({
      id: 0,
      type: 'user',
      chat: [{ type: 'text', message: plainText }],
    });
    return {
      type: 'BUTTON_CLICK',
      buttonClick: buttonOption
    };
  }

  setPlainText(): ContentBlock {
    const value = this.messageControl.value;
    this.messages.push({
      id: 0,
      type: 'user',
      chat: [{ type: 'text', message: value }],
    });
    return {
      type: 'PLAIN_TEXT',
      plainText: {
        plainText: value,
      }
    };
  }

  shouldTypingActivate(active: boolean, type: string) {
    return active && type === 'system' && this.interaction;
  }

  sendRatingMessage(userRating: PersonMessage['userRating'], message: MessageChat) {
    if (userRating === 'BAD') {
      this.mariaDialogsService.openPersonMessageRatingUserBad().pipe(
        tap((userRatingReason) => {
          if (userRatingReason) {
            this.sendEvent(userRating, message, userRatingReason);
          }
        }),
        filter(responseSupport => !!responseSupport),
        switchMap(() => {
          return this.confirmService.confirm({
            theme: 'maria',
            title: 'Agradecemos pela sua avaliação',
            message: 'Notamos que você não teve uma boa experiência com a nossa assistente digital.  Você gostaria de falar com nossos especialistas?',
            buttonCancel: {
              label: 'Fechar',
              show: true,
            },
            buttonConfirm: {
              label: 'Falar com o suporte',
              show: true
            }
          });
        }),
        tap(responseSupport => {

          if (responseSupport) {
            this.amplitudeService.sendEvent('ai_assistant_customer_support_redirected');
            this.whatsappService.sendMessage(`Olá, gostaria de falar com o suporte.`);
          }
        })
      ).subscribe({
        error: err => {
          this.utilsService.setErrorToast(err);
        }
      });
      return;
    }
    this.sendEvent(userRating, message);
  }

  sendEvent(userRating: PersonMessage['userRating'], message: MessageChat, userRatingReason?: string) {
    if (userRatingReason) {
      this.amplitudeService.sendEvent('ai_assistant_response_evaluation_reasons_sent', { evaluationReasons: userRatingReason });
    }

    this.personMessageEntityService.ratingMessage({
      id: message.id,
      userRating,
      userRatingReason
    })
      .pipe(
        map(response => {
          this.amplitudeService.sendEvent('ai_assistant_response_evaluation_sent', { evaluationValue: userRating });
          this.formatMessage(response, true);
        })
      )
      .subscribe({
        error: err => {
          this.utilsService.setErrorToast(err);
        }
      });
  }

  goTo(button: string) {
    const url = this.mariaUtilsService.getUrlByGoToEnums(button, this.crmPerson.patientAppDigitalStatus);
    if (url) {
      this.amplitudeService.sendEvent('ai_assistant_go_to_link_clicked', { stepName: url });
      this.dialogRef.close();
      this.router.navigate([url], {
        queryParamsHandling: 'merge'
      });
    }
  }

  shouldTypingActivateTitle() {
    return true;
  }
}

export interface MessageChat {
  id: number,
  chat: MessageByType[],
  type: string;
  rating?: string;
}

export interface MessageByType {
  message?: any;
  type: string,
  button?: string
  buttons?: { button: string }[];
}
